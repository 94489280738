import axios from 'axios';

axios.interceptors.request.use(
  async config => {
    const token = window.localStorage.getItem('auth');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => console.log(error)
);

axios.defaults.headers.common['Accept'] = 'application/ld+json';
axios.defaults.headers.common['Content-Type'] = 'application/ld+json';

export default axios;
